import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Stupid DNS Tricks" />
    <h1>Stupid DNS tricks</h1>
    
    <p><i>Published October 27, 2019</i></p>

    <p>
      The late night talk show host David Letterman used to have a segment 
      called <a href="https://www.youtube.com/watch?v=4-P-bEHKVIE">Stupid Pet Tricks</a>.
      Well, these tricks aren't stupid but it's a fun name.
    </p>

    <h3>Cheaping out</h3>

    <p>
      You can buy a cheap nameserver on a site like NameSilo, but use something better like Route 53
      for management of the actual site.
    </p>

    <p>
      See <a href="https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/migrate-dns-domain-in-use.html" target="_blank" rel="noopener noreferrer">this link</a>.
    </p>

    <h3>DNS fun</h3>

    <p>
      DNS can be very mysterious.  You don't know, did my change take effect?  How long will I have to wait before it does?
      On a Mac, you can use <code>networksetup -setdnsservers Wi-Fi</code> to quickly test DNS changes.
      When you're done, use <code>networksetup -setdnsservers Wi-Fi Empty</code> to revert back.
    </p>

    <p>
      <b>NOTE:</b> In my experiments, I found that setting specific name servers can completely take down
      your computer's ability to access the internet, albeit the hostname of the site you're trying to configure.
      You've been warned!
    </p>

    <p>
      In AWS Route 53, they let you enter TXT records.  What does that give you?  The ability to enter free
      text to validate the route you think you're hitting.  The <code>host</code> command helps with that.
    </p>

    <pre>host -t TXT bellagrams.org</pre>

    <p>So with this knowledge, you can test your DNS changes with something like this:</p>

    <ol>
      <li>Create your new hosted zone entry on Route 53</li>
      <li>
        To see changes quickly, change the TTL on your <code>A</code> and <code>NS</code> records 
        to be something low (say 60 seconds).  Do this on both Route 53 and your old nameserver provider.
      </li>
      <li>Use <code>networksetup -setdnsservers Wi-Fi</code> to point to Route 53 DNS servers</li>
      <ul>
        <li>You can validate these settings by executing <code>cat /etc/resolv.conf</code></li>
      </ul>
      <li>Use <code>host -t TXT</code> against your hostname to validate the text doesn't show up</li>
      <li>Add a TXT entry for that hosted zone with some fun text ("Hello, world!")</li>
      <li>Try <code>host -t TXT</code> and ensure your changes have been propagated</li>
      <li>Change the nameservers on your old name server host to point to AWS name servers</li>
      <li>Use <code>networksetup -setdnsservers Wi-Fi Empty</code> to revert back</li>
    </ol>

    You can see how your DNS changes have been propagated with this page <a href="http://whatsmydns.net" target="_blank" rel="noopener noreferrer">whatsmydns.net</a>.

    <p>Now you have the tools needed to ensure that your DNS config understanding matches reality.</p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
